import React from 'react';
import { Container, Typography, Hidden, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../style/style.css';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 80,
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
  description: {
    paddingBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(1),
    color: '#C4C4C4',
    textShadow: '1px 1px #000000',
  },
  buttonsContainer: {
    marginTop: theme.spacing(1),
  },
}));

function Home() {
  const classes = useStyles();

  console.log('test change'); // check if build/commit procedure works

  return (
    <section id="hero">
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.title}>
          <Hidden xsDown>
            <Typography
              variant="h3"
              component="h3"
              color="textPrimary"
              gutterBottom
            >
              {' '}
              Kashaya Online{' '}
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography
              variant="h4"
              component="h4"
              color="textPrimary"
              gutterBottom
            >
              Kashaya Online
            </Typography>
          </Hidden>
        </div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
          className={classes.buttonsContainer}
        >
          <Grid item>
            <Button variant="contained" color="primary" href="#/imgwords">
              Words with Pictures
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" href="#/allwords">
              All Words
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" href="#/sentences">
              Sentences
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" href="#/stories">
              Stories
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
          className={classes.buttonsContainer}
        >
          <Grid item>
            <Button variant="contained" color="primary" href="https://www.ling.upenn.edu/~gene/Kashaya/grammar/index.html">
              Grammar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" href="https://www.ling.upenn.edu/~gene/Kashaya/Vocabulary/sounds.html">
              Pronunciation
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" href="https://www.webonary.org/kashaya/">
              Dictionary
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
          className={classes.buttonsContainer}
        >
          <Grid item>
            <Button variant="contained" color="secondary" href="#/about">
              About
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" href="#/how">
              How to use this website
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </section>
  );
}

export default Home;
